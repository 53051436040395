.hiw_block
  font-size 16px
  line-height 24px
  padding: 64px 0 58px
  background: #fff
  .content_title
    margin-bottom: 61px
  .btn
    width: 222px
    display: block
    margin: 0 auto
    @media (max-width: 480px)
      width: 280px
  .item
    text-align: center
    margin-bottom: 20px
    .hiw_title
      font-size: 16px
      line-height: 25px
      font-weight: 600
      margin: 0
    &:last-child
      margin: 0
    img
      display: block
      position: relative
      margin: 0 auto 23px
    &:nth-child(1)
      img
        left: -24px
    &:nth-child(2)
      img
        left: -19px
    &:nth-child(3)
      img
        left: -14px
    p
      font-size: 16px
      line-height: 25px
      margin: 0 0 17px
.widget_container
  margin: 0 0 56px 0
  @media (max-width: 768px)
    width: calc(100% + 30px)
    margin: 0 -15px 56px
body.form_page
  .hero
    + .container
        padding-top: 130px
        @media (max-width: 768px)
          padding-top: 30px
  .main_slider_block
    margin-bottom: -160px
    position: relative
    z-index 99
    .bg_wrap
      box-shadow: 0 14px 80px rgba(#2f407d, .2)
      padding: 20px 0
    .ui-slider-handle
      &:before
        display none

.share_toolbox
  .at-svc-facebook
    display none!important
.content_block
  background: #fff
  margin-top: -96px
  border-radius: 4px
  padding: 30px
  margin-bottom: 55px
  @media (max-width: 480px)
    padding-left: 15px
    padding-right: 15px
  h3
    font-size 26px
    line-height 35px
    margin-bottom: 22px
    font-weight: 600
  p
    font-size 16px
    line-height 25px
    margin-bottom: 20px
    color #000
  .dotted-list
    margin-bottom: 25px
    padding-left: 0
    list-style: none
    li
      font-size: 16px
      color: #000
      line-height: 25px
      position: relative
      padding-left: 15px
      font-weight: normal
      &:before
        display: inline-block
        margin-right: 5px
        content: '•'
        color: #00BF32
        position: absolute
        left: 0
        top: 0
        vertical-align: middle
        line-height: 25px
  .numbered-list
    color #000
    counter-reset: list1
    padding: 0
    li
      list-style-type: none
      font-weight: 600
      position: relative
      margin-bottom: 25px
      font-size: 26px
      line-height: 35px
      .table,
      p
        font-size: 16px
        line-height: 25px
        margin-bottom: 25px
        font-weight: normal
        &:nth-child(1)
          margin-top: 25px
      &:before
        position: absolute
        left: 0
        top: 0
        counter-increment: list1
        content: counter(list1)
        opacity: 0
        font-size: 16px
        font-weight: 600
        width: 70px
        text-align: right
      li
        font-weight: normal
        padding-left: 80px
        font-size: 18px
        line-height: 25px
        li
          padding-left: 0
          &:before
            left: -80px
    ol
      counter-reset: list2
      padding-top: 25px
      padding-left: 0
    ol li:before
      counter-increment: list2
      content: counter(list1) ". " counter(list2)
      opacity: 1
    ol ol
      counter-reset: list3
    ol ol li:before
      counter-increment: list3
      content: counter(list1) "." counter(list2) ". " counter(list3)

.content_title
  font-size 30px
  line-height 30px
  font-weight: 600
  margin-bottom: 41px
  margin-top: 0
  @media (max-width: 767px)
    font-size 24px
    line-height 25px
    margin-bottom: 28px
.features_block
  padding: 80px 0
  margin-bottom: 80px
  background-color #f5f5fe
  @media (max-width: 768px)
    padding: 30px 0
  a
    text-decoration none
    color #000
    max-width: 314px
    width: 100%
    display inline-block
    &:hover
      .feat_img
        box-shadow: 0 5px 25px rgba(#41344d, .5)
        transition: .2s
      span
        color #2174d9
    @media (max-width: 768px)
      margin-bottom: 50px
  .feat_img
    box-shadow: 0 5px 15px rgba(#41344d, .25)
    transition: .2s
    border-radius: 24px
    margin-bottom: 24px
    @media (max-width: 768px)
      margin-bottom: 18px
  img
    width: 100%
  > a,
  > div
    text-align center
    @media (max-width: 992px)
      margin-bottom: 20px
      &:nth-child(3),
      &:nth-child(4)
        margin: 0
    @media (max-width: 767px)
      &:nth-child(3)
        margin-bottom: 20px
  span
    display block
    width: 100%
    text-align center
    font-size 16px

.main_page_offers
  padding-bottom: 106px
  @media (max-width: 767px)
    padding-bottom: 40px
  .thumbnail_wrap
    background-size cover
    @media (max-width: 992px)
      text-align center !important
      margin-bottom: 20px
      &:last-child
        margin-bottom: 0
    &:first-child
      .type_thumbnail_btn
        background-color #fff
        color #705ebb
        border-color: #705ebb
    &:nth-child(2)
      .type_thumbnail_btn
        background-color #fff
        color #0bbbdb
        border-color: #0bbbdb
    &:nth-child(3)
      .type_thumbnail_btn
        background-color #fff
        color #bf3a92
        border-color: #bf3a92

  .thumbnail
    max-width: 335px
    display inline-block
    text-align center
    padding: 0
    overflow hidden
    transition: .2s
    margin-bottom: 0
    h4
      font-size 18px
      line-height 22px
      font-weight 400
      margin-top: 0
    p
      margin: 0
      font-size 14px
      line-height 22px
      color #a9b3bd
    .header
      text-align center
      padding: 52px 10px 30px
    .body
      padding: 0 22px 40px
    .footer
      padding: 0 34px 40px
      border: 0 none
      .type_thumbnail_btn
        border-radius: 30px
        padding: 10px 32px
        transition: .2s
    &:hover
      box-shadow: 0 15px 40px rgba(#183052,0.15)
      transition: .2s
      .footer
        padding: 0 34px 40px
        .type_thumbnail_btn
          transition: .2s
.thumbnail_wrap
  &:first-child
    .thumbnail
      &:hover
        .type_thumbnail_btn
          background-color #705ebb
          border-color: #705ebb
          color #fff
  &:nth-child(2)
    .thumbnail
      &:hover
        .type_thumbnail_btn
          background-color #0bbbdb
          border-color: #0bbbdb
          color #fff
  &:nth-child(3)
    .thumbnail
      &:hover
        .type_thumbnail_btn
          background-color #bf3a92
          border-color: #bf3a92
          color #fff

.slider_info_block
  display -webkit-flex
  display -ms-flex
  display flex
  justify-content space-between
  list-style-type none
  padding: 10px 0 0
  margin: 0
  @media (max-width 767px)
    justify-content: space-between;
  li
    text-align left
    width: calc(100% / 3)
    @media (max-width 767px)
      width: auto
      margin-bottom: 10px
      text-align left
    &:nth-child(2)
      text-align center
      @media (max-width: 767px)
        text-align right
    &:last-child
      text-align right
      @media (max-width: 767px)
        text-align center
        margin 0
        display none
    .title
      font-size 18px
      color $GREEN
      display block
      @media (max-width 767px)
        font-size 14px
    .data
      font-size 14px
      display block
      @media (max-width: 767px)
        font-size 12px
.offer_categories_block
  position: relative
  @media (max-width: 767px)
    &:after
      position: absolute
      top: 0
      left: 100%
      display: block
      height: 100%
      width: 1px
      box-shadow: 0 0 10px 20px #fff
      content: ''
      z-index: 2
.offer_categories
  margin: 0
  padding: 0
  display: flex
  align-items: flex-end
  justify-content: flex-start
  position: relative
  z-index: 1
  max-width: 100%
  @media (max-width: 767px)
    overflow-x: auto
    padding-right: 20px
  li
    display: block
    margin-right: 34px
    font-size: 16px
    line-height: 19px
    a
      border-bottom: 3px solid transparent
      text-decoration: none !important
      padding-bottom: 17px
      display: block
      color: #000
      &.active
        border-bottom-color: $GREEN


.date_range
  margin: 0 0 40px
  padding: 0
  list-style-type none
  li
    float left
    margin-left: 10px
    width: calc(25% - (30px / 4))
    &:first-child
      margin-left: 0
    @media (max-width: 767px)
      width: calc(100% / 2 - 20px)
      margin:0 10px 20px
      &:first-child
      &:last-child
        margin:0 10px 20px
      &:last-child,
      &:nth-child(3)
        margin-bottom 0
  label
    position: relative;
    display inline-block
    margin 0
    width: 100%
    @media (max-width: 767px)
      width: 100%
    input
      position: absolute;
      left: 0
      top: 0
      width: 100%
      visibility: hidden;
      z-index 33
      margin 0
      height 100%
      + span
        background-color #e9eef3
        display inline-block
        height 100%
        padding: 10px
        border-radius: 4px
        color: #a9b3bd
        cursor pointer
        font-weight 400
        width 100%
        text-align center
      &:checked
        + span
            background-color $GREEN
            color #fff
      &:hover
        + span
          background-color rgba($GREEN, .8)
          color #fff

.c-table
	&-header
		.c-table-row
			height auto
			border 0
			margin-bottom: 8px
			padding-top: 0
			padding-bottom: 0
			&:hover
				background transparent
		.c-table-col
			font-size: 12px !important
			font-weight: 300
			text-transform: uppercase
			color #adb6bf
			&:nth-of-type(1)
				+above(651px)
					flex 1.4
				+below(650px)
					flex 1

	&-body
		background: white
	&-row
		position: relative
		display flex
		align-items center
		border-top 1px solid $color2
		transition background .2s
		+above(651px)
			height 100px
			padding 0 30px 0 20px
		+below(650px)
			padding 0
			height 70px
		+below(550px)
			height auto
			padding-top: 20px
			padding-bottom: 84px
		&:hover
			background: #f9fafb
	&-col
		display flex
		align-items center
		flex 1
		+below(550px)
			padding-right: 10px
			font-size: 13px
		&:nth-of-type(1)
			+above(651px)
				flex 1.4
				font-size: 16px
			+below(650px)
				flex 1
				font-size: 14px
			font-weight: 500
			+above(769px)
				min-width 240px
			img
				width 120px
				margin-right: 20px
				+below(768px)
					display: none
		&:nth-of-type(2)
			+above(1000px)
				flex 1.2
			+below(1000px)
				flex 1
		&:nth-last-of-type(1)
			justify-content flex-end
			flex 0
			+above(651px)
				min-width 170px
			+below(650px)
				min-width 124px
			+below(550px)
				position absolute
				left 0
				bottom 20px
				width 100%
		&:nth-last-of-type(2)
			padding-left: 20px


	&-footer
		display: block
		text-align: center
		padding: 15px 0
		border-top 1px solid $color2
		border-bottom 1px solid $color2
		cursor: pointer
		color black
		&:hover
			color black
			text-decoration: underline
	.btn-1
		font-size: 14px
		+above(769px)
			min-width 170px
		+below(768px)
			padding-left: 10px
			padding-right: 10px
			min-width auto
		+below(550px)
			width 100%
.c-table
  .btn-1
    background-color $GREEN
    color #fff
    padding: 14px 6px
    &:hover
      background-color rgba($GREEN, .8)
  .c-table-footer,
  .c-table-row
    border-top 1px solid #d8d8d8
  .c-table-header
    .c-table-row
      border 0 none

.link_column
	.aside_element
		margin-left: 30px
		width calc(100% / 4 - 30px)
		float left
		margin-bottom 30px
		@media (max-width 768px)
			width: 100%
			margin-left: 0
			margin-bottom: 18px
		&:first-child,
		&:nth-child(5n)
			margin-left: 0
	.aside_element_title
		font-size: 16px
		font-weight 600
		padding-bottom: 12px
	ul
		list-style-type none
		padding: 0
		margin: 0
		li
			display inline-block
			width: 100%
			font-size 14px
			line-height 18px
			font-weight 400
			padding-bottom: 2px
			a
				text-decoration none
				&:hover,
				&:focus,
				&:active
					text-decoration none
					color #000

.auth_wrap
  min-height: calc(100vh - 78px)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.auth_block,
.login_container
  width 437px
  max-width: 100%
  margin: 0 auto
  font-size: 16px
  line-height: 20px
  padding: 20px 0
  .title-forgot,
  .title
    font-size: 40px
    line-height: 35px
    margin: 0 0 49px
    font-weight: 800
  .title-forgot
    margin-bottom: 15px
    & + p
      color: #888
      margin: 0 0 30px
      &:last-child
        margin-bottom: 0
  &.success_send
    width: 600px
    .title-forgot
      margin-bottom: 24px
  .form-group
    margin-bottom: 24px
  .form-label
    font-size: 16px
    font-weight: normal
    line-height: 19px
    margin-bottom: 8px
    display: block
    position: static
  .form-control
    font-size: 16px
    height 45px
    background: #fff
    &:focus
      box-shadow: none
      border-color: $BLUE
  .dib
    display inline-block
  .form-check
    position: relative
    .form-check-label
      position: relative
      font-size: 16px
      font-weight: normal
      line-height: 20px
      padding-left: 29px
      cursor: pointer
      &:before
        content: ''
        position: absolute
        width: 20px
        height: 20px
        left: 0
        top: 0
        border-radius: 2px
        border: 1px solid $GREY
        background: #fff url('../img/check-ico.svg') 3px 4px no-repeat
    input[type="checkbox"]
      opacity: 0
      visibility: hidden
      position: absolute
      left: 0
      &:checked
        + .form-check-label
          &:before
            background-color: $GREEN
            border-color: $GREEN
  .btn
    padding: 11px 16px 10px
    margin-bottom: 20px
    &:last-child
      margin-bottom: 0

.feed_item
  &.banner_item
    background-color #45b2f4
    color #fff
    text-decoration none
    @media (max-width 600px)
      border 0 none
    .text_side
      width: calc(100% - 238px)
      color #fff
      @media (max-width 600px)
        padding: 12px 0 0
        width: calc(100% - 58px)
      @media (max-width 425px)
        width: calc(100% + 82px)
        padding: 0
    .img_block
      padding: 0 20px
      @media (max-width 600px)
        padding: 0 0 0 8px
        img
          width: 100%
    a
      display -webkit-flex
      display -ms-flex
      display flex
      align-items: center;
      &:hover,
      &:focus,
      &:active
        text-decoration none
        color #fff
    .banner_heading
      font-size 22px
      line-height 30px
      @media (max-width 600px)
        display block
        margin-bottom: 10px
        font-size 14px
        line-height 18px
        white-space: normal;
    .banner_paragraph
      font-size 16px
      line-height 30px
      margin: 0 0 14px
      color #d0e8f9
      @media (max-width 600px)
        font-weight 600
        margin-bottom: 10px
        font-size 12px
        line-height 18px
    .banner_span
      font-size 14px
      line-height 24px
      font-weight 600
      display -webkit-flex
      display -ms-flex
      display flex
      align-items: center
      color #fff
      @media (max-width 600px)
        font-size 12px
        line-height 18px
      svg
        padding-left: 10px
        width: 33px
  &.feed_rating
    display: flex
    align-items: center
    padding: 30px
    h2
      margin-top: 0
      font-size: 30px
      +below(767px)
        font-size: 24px
    p
      margin-bottom: 20px
    img
      display: block
      margin-right: 30px
    .btn
      padding: 13px 22px
      +below(767px)
        width: 100%
    .feed_rating_info
      width: calc(100% - 170px)
      +below(767px)
        width: 100%
        text-align: center
    +below(767px)
      flex-direction: column
      padding: 20px 15px
      p
        margin-bottom: 0
      img
        margin-right: 0
        margin-bottom: 20px
.conditions-wrapp
  background: #F7F8FA
  padding-bottom: 60px
  @media (max-width: 767px)
    padding-bottom: 41px
  .content_block
    background: none
    margin-top: 0
    padding: 0
    border-radius: 0
    margin-bottom: 0
.panel-group
  .panel
    border:none
    box-shadow: 0 10px 20px rgba(#585858,.1)
    + .panel
      margin-top: 20px
    &:hover,
    &.active
      box-shadow: 0 10px 20px rgba(#585858,.25)
    .panel-heading
      background: #fff
      padding: 0
    .panel-body
      padding: 0 30px 30px
      background: #fff
      border: none !important
      @media (max-width: 767px)
        padding-left: 20px
        padding-right: 20px
        padding-bottom: 20px
      @media (max-width: 480px)
        padding-left: 15px
        padding-right: 15px
        padding-bottom: 15px
      p
        margin-bottom: 25px
        font-size: 16px
        color: #000
        line-height: 25px
      ul
        margin-bottom: 25px
        padding-left: 0
        list-style: none
        li
          font-size: 16px
          color: #000
          line-height: 25px
          position: relative
          padding-left: 15px
          font-weight: normal
          &:before
            display: inline-block
            margin-right: 5px
            content: '•'
            color: #00BF32
            position: absolute
            left: 0
            top: 0
            vertical-align: middle
            line-height: 25px
    .panel-title
      margin: 0
      padding: 0
      a
        font-weight: 600
        text-decoration: none !important
        font-size: 18px
        line-height: 21px
        display: block
        padding: 20px 30px 19px
        position: relative
        color: #000
        @media (max-width: 767px)
          padding: 20px
        @media (max-width: 480px)
          padding: 20px 35px 16px 18px
          font-size: 16px
          line-height: 22px
        &:before
        &:after
          position: absolute
          content: ''
          display: block
          width: 18px
          height: 2px
          top: calc(50% - 1px)
          right: 25px
          background-color: #888
          transition: .2s
          @media (max-width: 767px)
            right: 23px
        &:after
          transform: rotate(90deg)
        &:hover
          color: #000
    &.active
      .panel-title
        a
          &:after
            transform: rotate(0deg)
          &:before,
          &:after
            background-color: #00BF32

