body
  font-family: 'aktifo', sans-serif, '-apple-system'
  font-weight 400
  letter-spacing: -0.25px
  font-size: 16px
  line-height: 25px
  background: #F7F8FA
  overflow-x: hidden

html
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  overflow-x: hidden
  &.active_menu
    overflow-x: hidden


.h5,
h5
  font-size: 18px
  font-weight: 600
.h6,
h6
  font-size: 16px
  font-weight: 600

.tooltip_block
  position: absolute
  left 35%
  top -20px
  width: 20px
  height 20px
  text-align center
  cursor: pointer
  @media (max-width: 768px)
    display none
  p
    position: absolute
    visibility: hidden
    z-index -1
    background #fff
    box-shadow: 0 5px 18px rgba(42,67,109,0.27)
    padding: 16px
    border-radius: 8px
    transform: translateY(-100%)
    width: 280px
    height 90px
    top 30px
    left: -40px
    text-align left
  &:hover
    display block
    p
      visibility: visible
      z-index 22
.mw_five
  max-width: 500px
.btn
  padding: 8px 12px
.btn-primary
  background-color $GREEN
  border-color: $GREEN
  font-size 16px
  @media (max-width: 768px)
    font-size 14px
    padding: 8px 12px !important
  &:hover,
  &:focus,
  &:visited,
  &:active,
  &:active:hover,
  &:active:focus
    background-color rgba($GREEN, .8)
    border-color: rgba($GREEN, .2)
    outline: none
.xs_hidden_ovf
  @media (max-width: 992px)
    overflow-x hidden
  &.media_menu
    overflow: hidden;
    &.is_active
      overflow visible
.width_full
  width: 100%
.tablet-hide
  @media (max-width: 991px)
    display: none !important
.tablet-show
  display: none !important
  @media (max-width: 991px)
    display: block !important
    .btn
      margin: 0 10px 10px
      width: calc(100% - 20px)
      color: #fff !important;
.title_block
  margin-bottom: 36px
  .hero_title
    margin-bottom: 0
    @media (max-width: 767px)
      text-align: center
    & + .subtitle
        color #fff
      @media (max-width: 767px)
        text-align: center
    & + .hero_subtitle
      @media (max-width: 767px)
        text-align: center
        // text-align left
  .content_title
    margin-bottom: 0
  .subtitle
    font-size 18px
    line-height 40px
    display inline-block
    width: 100%
    @media (max-width: 768px)
      padding-top: 8px
      font-size 16px
      line-height 22px
.offer-title-block
  margin-bottom: 30px
  padding-top: 50px
  @media (max-width: 767px)
    padding-top: 31px
    margin-bottom: 25px
  .content_title
    font-size: 24px
    line-height: 35px
    margin: 0
    font-weight: 800
    @media (max-width: 767px)
      font-size: 18px
      line-height: 25px
  .subtitle
    font-size: 18px
    line-height: 35px
    @media (max-width: 767px)
      padding-top: 25px
      margin-bottom: 25px
      &:last-child
        margin-bottom: 0
