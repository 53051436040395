.welcome
  padding-bottom: 100px
  .block
    background: #fff
    width: calc(50% - 10px)
    border-radius: 4px
    overflow: hidden
    margin-bottom: 20px
    box-shadow: 0 10px 20px rgba(#585858, .1)
    .btn
      width: 193px
    .link-inline
      display: inline-block
      margin-left: 20px
      vertical-align: middle
    p
      font-size: 18px
      line-height: 25px
      margin-bottom: 25px
    &.credit-chance
      width: 100%
      margin-top: -96px
      padding: 72px 66px 64px
      background-image: url('../img/credit-chance-image.png')
      background-repeat: no-repeat
      background-position: 100% 100%
      .block_title
        margin-bottom: 19px
    &.credit-jurists,
    &.credit-seletion
      display: flex
      align-items: flex-start
      justify-content: space-between
      padding: 30px 25px
      .block_info
        width: calc(100% - 99px)
    .block_title
      font-size: 26px
      font-weight: 600
      line-height: 35px
      margin: 0 0 13px
  .auth-blocks
    display: flex
    align-self: flex-start
    justify-content: space-between
    flex-wrap: wrap
