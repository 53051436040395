@import 'font.styl'

$COLOR_1 = #00BF32
$COLOR_2 = #d8d8d8
$YELLOW = #f0c02f
$RED = #e36d37

// --------------------- NEW COLORS
$BLUE = #1F1778
$LINK = #2C75FF
$GREY = #D5D5D5
$GREEN = #00BF32


$FONT_REGULAR = 'aktifo', sans-serif, '-apple-system'
$FONT_BOLD = 'aktifo', sans-serif, '-apple-system'

$BASE_FONT = 'aktifo', sans-serif, '-apple-system'

@import 'nib'
@import '../../../../node_modules/axis/axis/index.styl'
@import '../../../../node_modules/rupture/rupture/index.styl'
@import '../../../../public/zemca/bower_components/jeet/stylus/jeet/index.styl'

@import '_header.styl'
@import '_mainslider.styl'
@import '_layout.styl'
@import '_feed.styl'
@import '_footer.styl'
@import '_form.styl'
@import '_options.styl'
@import 'main.styl'
@import '_review_carousel.styl'
@import '_auth.styl'
a
	color: $LINK
	&:hover
		color: $LINK

.container
	width: 1310px
	max-width: 100%
	@media (max-width: 480px)
		padding-left: 10px
		padding-right: 10px
		.row
			margin-right: -10px
			margin-left: -10px
		.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9
			padding-left: 10px
			padding-right: 10px

.hidden_block
	display: none

.text_inline
	white-space: nowrap

.main_nav_wrapp
	position: relative
	z-index: 2
	background: #F7F8FA
.main_nav
	margin-top: -95px
	display: flex
	align-items: stretch
	margin-bottom: 81px
	justify-content: space-between
	@media (max-width: 991px)
		flex-wrap: wrap
		margin-bottom: 50px
	@media (max-width: 767px)
		flex-wrap: wrap
		margin-bottom: 62px
		margin-top: -73px
	@media (max-width: 550px)
		justify-content: center
	.item
		width: calc(25% - 15px)
		background: #fff
		border-radius: 4px
		box-shadow: 0 10px 20px rgba(#585858, .1)
		display: flex
		flex-direction: column
		align-items: flex-start
		justify-content: center
		text-decoration: none !important
		transition: box-shadow .2s
		padding: 25px 20px 34px 29px
		@media (max-width: 991px)
			width: calc(50% - 15px)
			margin-bottom: 30px
		@media (max-width: 550px)
			width: 100%
			max-width: 300px
			margin-bottom: 11px
			padding: 25px 10px 20px 19px
		&:hover
			box-shadow: 0 10px 20px rgba(#585858, .25)
		&_ico
			display: block
			margin-bottom: 25px
		&_name
			font-weight: 600
			color: #000
			font-size: 18px
			line-height: 20px
			display: block
			margin-bottom: 4px
		&_info
			font-size: 16px
			display: block
			color: #888
.form-wrapper
	background: #F7F8FA
	position: relative
	z-index: 2
	.main_slider_block
		margin-top: -95px
		margin-bottom: 72px
.finance_form
	.credit_carma_form
		font-size 15px
		padding 30px 0
		@media (max-width: 480px)
			padding: 0
		.step
			border 0
			background: white
			border-radius: 4px
		.money_slider_wrap
			+above(500px)
				padding-top: 30px
				padding-left: 50px
				padding-right: 50px
			+below(500px)
				padding-top: 20px
				padding-left: 20px
				padding-right: 20px
		.slider_notation
			color: #767676
			#money_box,
			#term_box
				color #000
				margin-left: 10px
				font-size 21px
			#money_box
				font-weight 700
		.slider_section
			border-bottom 0
			padding-bottom: 20px
			.ui-widget-header
				background: $COLOR_1
				box-shadow none
			.ui-widget-content
				background: 0
				height: 21px
				box-shadow none
				cursor: pointer
				&:before
					content: ''
					position: absolute
					left: 0
					top: 9px
					width: 100%
					height: 3px
					background: #c2dae6
				.ui-state-default
					top -5px
					height: 30px
					width: 30px
					border-radius: 50%
					background-color $COLOR_1
					cursor pointer
					border 0 none
					&:before
						content '||'
						line-height 28px
						text-align center
						color #fff
						font-weight 400
						display inline-block
						padding-left: 10px
						letter-spacing: 3px
					&:after
						display: none
			.ui-slider-horizontal
				.ui-slider-range
					top 9px
					height: 3px
		.slider_row
			padding-right: 0
		.meter_list
			display: none
		.start_point,
		.end_point
			font-size: 16px
		.form_body
			+above(500px)
				padding-left: 50px
				padding-right: 50px
			+below(500px)
				padding-left: 20px
				padding-right: 20px
			.form_section_head
				color: #333
				margin: 30px 0
				+above(500px)
					font-size: 18px
			.form_label_block
				+above(500px)
					font 15px/1.2 $BASE_FONT
			.form_input_block
				border 0
				border-bottom 2px solid #dadada
				line-height: 1.2
				border-radius 0
				padding-left: 15px
				+above(500px)
					font-size 16px
				+below(500px)
					font-size: 14px
			.form_block
				margin-bottom: 30px
				&.invalid
					.form_input_block
						border 0
						border-bottom 2px solid #d13f19
		.btn_gold
			background: $COLOR_1
			border 0
			text-shadow none
			color: white
			font-size 20px
			min-width 250px
			font-weight: 300
			border-radius: 4px
			@media (max-width 768px)
				padding: 10px 8px
				font-size 14px
				min-width: 0
				width: 100%
				max-width: 250px
				border-radius: 4px
				display: inline-block
			&:hover,
			&:active,
			&:focus
				background: rgba($COLOR_1,0.8)
		.drop_list
			border 0
			border-bottom 2px solid #dadada
		.credit_notification
			background: $COLOR_1
			text-shadow none
			overflow: hidden
			display none
			+above(992px)
				border-radius 10px 10px 0 0
				padding-left: 50px
			+between(500px,992px)
				padding-left: 50px
			+below(500px)
				padding-left: 15px
			+below(450px)
				padding-left: 0
			.percent_rate
				padding-top: 0
			.probability
				line-height: 1.2
				padding-left: 0
				+above(992px)
					font-size: 16px
				+between(500px,992px)
					font-size: 14px
			.credit_hint
				line-height: 1.2
				+above(992px)
					font-size: 20px
				+between(500px,992px)
					font-size: 18px
			.credit_info_block
				+above(450px)
					display flex
					align-items center
				+above(992px)
					min-height 110px
				+between(500px,992px)
					min-height 90px
				&:not(.percent_rate)
					padding-top: 0
		.form_head
			background: none
			padding-top: 0
			border-bottom 0
			+above(500px)
				padding-left: 50px
			+below(992px)
				margin-top: 30px
			.form_header
				+above(650px)
					font-size: 30px
				+below(450px)
					font-size: 20px
			.form_subheader
				+above(650px)
					font-size: 19px
				+below(450px)
					font-size: 16px

.finance_form
	.logo_wrap
		padding-right: 15px
	.feed_container
		margin: 0 auto
	.success_block
		margin: 0 auto
	.title
		text-align center
	// padding: 0 15px
	.feed_item_for_form
		padding: 50px 60px
		border 2px solid #e9eef3
		border-radius: 4px
		margin-bottom: 20px
		transition: .2s;
		position: relative
		@media (max-width 992px)
			padding: 30px
		@media (max-width 767px)
			padding: 20px 15px

.media_tl
	text-align left
	.slider_text_type_large
		text-align left
.media_tc
	text-align center
	.slider_text_type_large
		text-align center
.media_tr
	text-align right
	.slider_text_type_large
		text-align right

@media (min-width 768px)
	.feed_item_for_form
		.media_tl
			text-align left
			.slider_text_type_large
				text-align left
		.media_tc
			text-align left
			.slider_text_type_large
				text-align left
		.media_tr
			text-align left
			.slider_text_type_large
				text-align left
.offers-wrapper
	display: flex
	align-content: flex-start
	justify-content: space-between
	@media (max-width: 991px)
		flex-wrap: wrap
	.offers-left
		width: 920px
		@media (max-width: 1200px)
			width: 70%
		@media (max-width: 991px)
			width: 100%
	.aside-wrapper
		width: 335px
		@media (max-width: 1200px)
			width: 27%
		@media (max-width: 991px)
			width: 27%
			margin: 0 auto 30px
		@media (max-width: 767px)
			width: 335px
			max-width: 100%
.finance_form
	.credit_carma_form
		.step[data-step="5"]
			.form_head
				@media (max-width: 992px)
					margin-top: 0
.aside-wrapper
	.credit-rate
		background: #603DC1
		border-radius: 4px
		box-shadow: 0 10px 20px rgba(#585858,.1)
		padding: 40px 30px
		img
			display: block
			margin: 0 auto 38px
		.credit-rate-title
			text-align: center
			font-size: 26px
			line-height: 25px
			margin:0 0 29px
			font-weight: 600
			color: #fff
		p
			text-align: center
			color: #fff
			font-size: 16px
			line-height: 25px
			margin: 0 0 24px
		.btn
			display: block
			width: 208px
			margin: 0 auto
