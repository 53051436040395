.finance_form
	.feed_item
		margin-right: -15px
		margin-left: -15px
	.form_block
		.btn
			display inline-block
			margin 0
			background transparent
			padding 17px 50px
			vertical-align top
			border-radius 50px
			font-size 16px
			font-weight bold
			box-shadow inset 0 1px 0 rgba(255, 255, 255, 0.5)
			//appearance button
			cursor pointer

	.btn_gold
		background #f0a600
		background linear-gradient(to bottom, #fecf00, #f0a600)
		border 1px solid #ed9100
		border-bottom-width 3px
		color #464649
		text-shadow 0 1px 1px rgba(255, 255, 255, 0.6)
		margin 10px 0
		padding: 17px


		&:hover
			background: linear-gradient(to bottom, #ffd728, #f3b100)
			border-color: #f79c0d
			text-decoration none

		&:focus, &:active
			background: #f3b401
			box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.3)
			border-color: #ed9100
			outline: 0

	.btn_blue
		background #1e5799
		background linear-gradient(to bottom, #0986e5, #1e5799)
		color #fff
		padding 7px 50px
		font-size 12px
		font-weight normal
		border 1px solid #173e69
		text-shadow 1px 1px 1px rgba(0,0,0,0.5)

	.btn_green
		background linear-gradient(to bottom, #4ba614, #018c00)
		display block
		border 1px solid #096d04
		color #fff
		text-align center

		text-shadow 1px 1px 1px rgba(0, 0, 0, .5)

		&:hover
			text-decoration none
			background linear-gradient(top, #469b15, #038602)
		&:active,
		&:focus
			text-decoration none
			box-shadow inset 0 0 5px rgba(0, 0, 0, .4)



