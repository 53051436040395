.feed_container
  max-width: 940px
  margin 0 auto 60px
  @media (max-width: 991px)
    width: 100%
.feed_item,
.feed_item_for_form
  background: #fff
  padding: 21px 30px 20px
  border-radius: 4px
  margin-bottom: 20px
  overflow: hidden
  transition: .2s
  position: relative
  box-shadow: 0 10px 20px rgba(#585858,.1)
  @media (max-width: 767px)
    padding: 21px 20px 20px
    margin-bottom: 10px
  &:hover
    box-shadow: 0 10px 20px rgba(#585858,.25)
    transition: .2s
  &:last-child
    margin-bottom: 0
  .feed_info
    float: left
    width: calc(100% - 220px)
    @media (max-width: 650px)
      float: none
      width: 100%
  .logo_wrap
    max-width: 163px
    width: 100%
    margin-right: 52px
    padding-top: 14px
    float: left
    @media (max-width: 650px)
      float: none
      max-height: 35px
      padding-top: 0
      margin-bottom: 16px
      max-width: 100%
      img
        max-height: 35px
    img
      max-width: 100%
      display: block
  .feed_info
    display: flex
    align-items: center
    justify-content: space-between
    flex-wrap: wrap
    .btn
      width: 191px
      box-shadow: 0 5px 15px rgba(#00BF32, .35)
  .partners_hint
    ul
      margin: 0
      padding: 0
      li
        list-style: none
        display: block
        line-height: 25px
        margin-bottom: 10px
        position: relative
        padding-left: 18px
        font-size: 16px
        &:last-child
          margin-bottom: 0
        &:before
          display: block
          content: "+"
          color: #00BF32
          position: absolute
          left: 0px
          top: 0px
          font-size: 14px
          vertical-align: middle
          line-height: 25px
  a.show-more
    text-decoration: none !important
    color: #888888
    font-size: 14px
    line-height: 35px
    display: inline-block
    width: 80px
    span
      color: #888888
      &.hide
        display: none
        color: #2C75FF
        &.active
          display: block !important
    &:hover
      color: #2C75FF
      span
        color: #2C75FF
  .collapse-control
    padding-bottom: 8px
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    &:before
      width: calc(100% - 100px)
      content: ''
      display: block
      height: 1px
      background: #D5D5D5
  .feeds_item_collapse
    width: 100%
    overflow: hidden
    transition: height .2s
    height: 0
    @media (max-width: 767px)
      height: 0
  .mobile-controls
    align-items: center
    width: 100%
    justify-content: space-between
    @media (max-width: 767px)
      display: flex
      padding-top: 19px
      border-top: 1px solid #D5D5D5
      .btn
        margin-top: 0
      .show-more,
      .show-more span
        font-size: 14px
        line-height: 35px
    @media (max-width: 480px)
      .btn
        max-width: 164px
  .recalculate-wrapp
  .percent-wrapp
  .amount-wrapp
    width: 140px
    margin-bottom: 11px
    @media (max-width: 767px)
      margin-bottom: 18px
  .feed-btn-wrapper
    margin-bottom: 11px
  .title
    font-size 18px
    line-height: 35px
    font-weight 600
    width: 100%
    color #000
    display block
    margin-bottom: 15px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    @media (max-width: 767px)
      margin: 0
      max-width: 100%
      line-height: 25px
  span
    display block
    font-size 14px
    line-height 25px
    color #888
    @media (max-width: 767px)
      font-size 14px
      line-height 25px
  i,
  .recalculate
    font-size 18px
    color: #000
    line-height 25px
    font-style: normal
    white-space: nowrap
    @media (max-width: 768px)
      font-size 14px
      line-height 18px
    @media (max-width 430px)
      font-size 14px
      white-space: nowrap

  .amount-wrapp
    @media (max-width: 767px)
      width: 100%
      line-height: 25px
      margin-bottom: 23px
      .amount
        color: #888
      .mobile-show
        display: inline-block
        line-height: 25px
  .feed_item_for_form
    @media (max-width 991px)
      i,
      b
        font-size 14
  b
    font-weight: 800
  .action_btn
    padding: 13px 12px
    @media (max-width: 991px)
      font-size 14px
    @media (max-width 767px)
      margin-top: 18px
  .custom_padding
    padding-top: 40px
    @media (max-width: 767px)
      padding-top: 16px
// .feed_item .media_pt,
// .feed_item_for_form .media_pt
//   padding-top: 40px;

// .feed_item .feed_head,
// .feed_item_for_form .feed_head
//   @media (max-width 768px)
//     position: absolute;
//     top: 20px;
//     right: 20px;
//     width: 100%;
//     max-width: 120px;

// .feed_item,
// .feed_item_for_form
//   position: relative;

.best_offers_wrapp
  background: #F7F8FA
  padding-bottom: 80px
  @media (max-width: 991px)
    padding-bottom: 50px
  @media (max-width: 767px)
    padding-bottom: 30px
  .tabs-wrapper
    @media (max-width: 767px)
      position: relative
      &:after
        position: absolute
        top: 0
        left: 100%
        display: block
        height: 51px
        width: 1px
        box-shadow: 0 0 10px 20px #f7f8fa
        content: ''
        z-index: 2
  .nav-tabs
    display: flex
    align-items: center
    justify-content: flex-start
    border: none
    margin-bottom: 20px
    @media (max-width: 767px)
      overflow-x: auto
    >li
      margin-bottom: 0px !important
      >a
        padding: 0 0 13px !important
        border: none
        line-height: 20px
        border-bottom: 3px solid transparent
        background: none !important
        color: #000 !important
        font-size: 16px
        transition: border-bottom-color .2s
        white-space: nowrap
        &:hover
          border-bottom: 3px solid #D5D5D5
      &.active>a,
      &.active>a:focus,
      &.active>a:hover
        border: none
        border-bottom: 3px solid $GREEN
      &:not(:last-child)
        margin-right: 24px
      &:last-child
        a
          @media (max-width: 767px)
            margin-right: 20px
  .tab-offers-list
    display: flex
    align-items: stretch
    justify-content: space-between
    @media (max-width: 991px)
      flex-wrap: wrap
    .offer-item
      display: flex
      flex-direction: column
      align-items: stretch
      justify-content: center
      padding: 24px 20px 20px
      background: #fff
      border-radius: 3px
      box-shadow: 0 10px 20px rgba(#585858, .1)
      transition: box-shadow .2s
      width: calc(25% - 15px)
      @media (max-width: 991px)
        width: calc(50% - 15px)
        margin-bottom: 30px
      @media (max-width: 550px)
        width: 100%
        margin-bottom: 30px
      &:hover
        box-shadow: 0 10px 20px rgba(#585858, .25)
      .offer-logo
        display: block
        margin-bottom: 19px
        max-height: 29px
        align-self: flex-start
      .offer-title
        font-weight: 800
        color: #000
        font-size: 18px
        margin: 0
        line-height: 25px
      p
        font-size: 14px
        line-height: 25px
        color: #888
      .offer-credit-info
        display: flex
        align-items: center
        justify-content: space-between
        border-bottom: 1px solid $GREY
        padding-bottom: 19px
        margin-bottom: 19px
        .value
          font-size: 16px
          line-height: 25px
        .title
          color: #888888
          line-height: 25px
          font-size: 14px
        .offer-valute
          .value
            font-weight: 800

.mobile-show
  @media (min-width: 768px)
    display: none !important
.mobile-hide
  @media (max-width: 767px)
    display: none !important