.main_slider_block
  margin 0 auto
  background: #fff
  border-radius: 4px
  overflow: hidden
  box-shadow: 0 10px 20px rgba(#585858, .1)
  .bg_wrap
    padding-top: 30px
  @media (max-width: 992px)
    padding: 0
  @media (max-width: 767px)
    margin-bottom: 0
  @media (max-width: 480px)
    margin-bottom: 0
    padding-bottom: 30px
  .mp_slider_result
    position: relative
    @media (max-width: 767px)
      padding-top: 10px
      margin-bottom: 30px
      .slider_text_type_large
        font-size 16px
        white-space: nowrap
      &:last-child
        margin 0
    &:nth-child(2)
      &:before
        content: '+'
        left calc(-50% + -14px)
    &:nth-child(3)
      &:before
        content: '='
        left -55px
        @media (max-width 992px)
          left: -35px
    &:before
      position: absolute;
      color: #a9b3bd
      font-size 14px
      font-size 22px
      top 4px
      left -50%
      transform: translateX(56px);
      @media (max-width: 767px)
        display none
  #slider
    width: 100%
    margin: 0 0 -1px
  .ui-slider-handle
    top: -9px
    height 20px
    width: 20px
    border-radius: 50%
    background-color $GREEN
    box-shadow: 0 5px 15px rgba(#00BF32,.35)
    border 0 none
    cursor pointer
    margin-left: -10px
    &:focus,
    &:active
      outline none
      background-color $GREEN
  .ui-slider-horizontal
    height: 3px
    border 0 none
    background-color #D5D5D5
  .ui-widget-header
    position: absolute;
    height 100%
    background-color $GREEN
  .feed_col_center
    text-align center

  .slider_text_type_medium
    font-size 18px
    font-weight: 600
    color: #000
    line-height: 35px
    display: flex
    padding: 3px 10px
    align-items: center
    justify-content: flex-start
    width: 100%
    position relative
    @media (max-width: 767px)
      font-size 15px
      white-space: nowrap;
  .slider_text_type_large
    font-size 18px
    display inline-block
    width: 100%
    position relative
    padding-top: 6px
    @media (max-width: 767px)
      text-align left
      font-size 16px
      padding-top: 0

  .slide
    background-color #fff
    margin-bottom: 40px
    @media (max-width: 767px)
      overflow: visible;
    .slider_value
      max-width: 100%
      width: 100%
      padding-right: 4px
      @media (max-width: 767px)
        max-width: 100%
        width: 100%
    .btn-primary
      padding: 13px 62px
      @media (max-width: 768px)
        width: 100%
    i
      font-style: normal
      color #a9b3bd
      font-size 14px
      line-height 16px
      @media (max-width: 767px)
        font-size 12px
        display inline-block
        width 100%
    .col_padding_top
      padding-top: 4px
    .left,
    .right
      padding-bottom: 0px
    .left
      .slider_text_type_medium
        text-align left
        @media (max-wid'+'th 767px)
          font-size 16px
    .right
      .slider_text_type_medium
        text-align right
        font-size 30px
        font-weight 600
        line-height 40px
        top -8px
        left: -16px
        white-space: nowrap
        input
          text-align: right
        @media (max-width: 767px)
          font-size 18px
          padding-right: 10px
    .inside_container
      padding: 0px
      input
        font-weight 600
      @media (max-width: 767px)
        padding: 0
  .circle
    font-style normal
    position: absolute;
    height 28px
    width: 28px
    border-radius: 50%
    background-color #fff
    line-height 28px
    left: -40px
    top: 50%
    transform: translateY(-50%)
    overflow: hidden
  .header_btns
    display: none
    list-style-type none
    margin 0 0 44px 0
    padding 0
    width 100%
    border-radius: 4px 4px 0 0
    overflow hidden
    @media (max-width: 992px)
      display none
    li
      float left
      font-size 14px
      line-height 18px
      padding: 26px 30px
      background-color #e9eef3
      position: relative;
      color #a9b3bd
      width: calc(100% / 3)
      text-align center
      span
        position: relative;
      &:first-child
        background-color #fff
        color $GREEN
        .circle
          background-color $GREEN
          color #fff
      &:last-child
        &:after
          content: ''
          position: absolute;
          height: calc(100% - 2px)
          width: 1px
          left: -1px
          top: 50%
          transform: translateY(-50%)
          background-color #d6dce3
.slider_border_wrap
  border: 1px solid #D5D5D5
  border-radius: 4px 4px 0 0
.slider_title_type_medium
  font-size 18px
  line-height: 20px
  margin-bottom: 18px
  color: #000
  margin-bottom: 10px
