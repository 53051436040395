.reviews-carousel
  overflow: hidden
  max-width: 100%
  background: #F7F8FA
  padding: 80px 0
  @media (max-width: 767px)
    padding: 56px 0
  .container
    position: relative
  .content_title
    @media (max-width: 480px)
      line-height: 35px
      max-width: 50%
  .owl-navigation
    position: absolute
    right: 0
    top: 0
    @media (max-width: 767px)
      top: 16px
      right: 34px
    .owl-prev,
    .owl-next
      background: none
      border: none
      padding: 0
      outline: none !important
      span
        display: block
        background: url('../img/review-carousel-arrow.svg') 0 0 no-repeat
        width: 24px
        height: 17px
        opacity: .5
        &:hover
          opacity: 1
    .owl-prev
      margin-right: 18px
      span
        background-image: url('../img/review-carousel-arrow-prev.svg')
  .owl-carousel
    .owl-stage-outer
      overflow: visible
  .review-name
    font-weight: 600
    font-size: 18px
    line-height: 28px
    margin-bottom: 10px
  p
    font-size: 16px
    line-height: 25px
    margin-bottom: 25px
  .review-date
    font-size: 14px
    line-height: 25px
    color: #888
  .review-rate
    display: flex
    align-items: center
    justify-content: flex-start
    margin-bottom: 15px
    span
      display: block
      width: 12px
      height: 12px
      margin-right: 2px
      background: url('../img/review-star.svg') 0 0 no-repeat
