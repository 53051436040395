@font-face {
    font-family: 'aktifo';
    src: url('../fonts/aktifoa-black-webfont.eot');
    src: url('../fonts/aktifoa-black-webfont.eot?#iefix') format('embedded-opentype'),
            url('../fonts/aktifoa-black-webfont.woff2') format('woff2'),
            url('../fonts/aktifoa-black-webfont.woff') format('woff'),
            url('../fonts/aktifoa-black-webfont.ttf') format('truetype'),
            url('../fonts/aktifoa-black-webfont.svg#aktifo_ablack') format('svg');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'aktifo';
    src: url('../fonts/aktifoa-blackoblique-webfont.eot');
    src: url('../fonts/aktifoa-blackoblique-webfont.eot?#iefix') format('embedded-opentype'),
            url('../fonts/aktifoa-blackoblique-webfont.woff2') format('woff2'),
            url('../fonts/aktifoa-blackoblique-webfont.woff') format('woff'),
            url('../fonts/aktifoa-blackoblique-webfont.ttf') format('truetype'),
            url('../fonts/aktifoa-blackoblique-webfont.svg#aktifo_ablack_oblique') format('svg');
    font-weight: 600;
    font-style: italic;

}




@font-face {
    font-family: 'aktifo';
    src: url('../fonts/aktifoa-bold-webfont.eot');
    src: url('../fonts/aktifoa-bold-webfont.eot?#iefix') format('embedded-opentype'),
            url('../fonts/aktifoa-bold-webfont.woff2') format('woff2'),
            url('../fonts/aktifoa-bold-webfont.woff') format('woff'),
            url('../fonts/aktifoa-bold-webfont.ttf') format('truetype'),
            url('../fonts/aktifoa-bold-webfont.svg#aktifo_a_boldbold') format('svg');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'aktifo';
    src: url('../fonts/aktifoa-boldoblique-webfont.eot');
    src: url('../fonts/aktifoa-boldoblique-webfont.eot?#iefix') format('embedded-opentype'),
            url('../fonts/aktifoa-boldoblique-webfont.woff2') format('woff2'),
            url('../fonts/aktifoa-boldoblique-webfont.woff') format('woff'),
            url('../fonts/aktifoa-boldoblique-webfont.ttf') format('truetype'),
            url('../fonts/aktifoa-boldoblique-webfont.svg#aktifo_abold_oblique') format('svg');
    font-weight: 700;
    font-style: italic;

}




@font-face {
    font-family: 'aktifo';
    src: url('../fonts/aktifoa-book-webfont.eot');
    src: url('../fonts/aktifoa-book-webfont.eot?#iefix') format('embedded-opentype'),
            url('../fonts/aktifoa-book-webfont.woff2') format('woff2'),
            url('../fonts/aktifoa-book-webfont.woff') format('woff'),
            url('../fonts/aktifoa-book-webfont.ttf') format('truetype'),
            url('../fonts/aktifoa-book-webfont.svg#aktifo_abook') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'aktifo';
    src: url('../fonts/aktifoa-bookoblique-webfont.eot');
    src: url('../fonts/aktifoa-bookoblique-webfont.eot?#iefix') format('embedded-opentype'),
            url('../fonts/aktifoa-bookoblique-webfont.woff2') format('woff2'),
            url('../fonts/aktifoa-bookoblique-webfont.woff') format('woff'),
            url('../fonts/aktifoa-bookoblique-webfont.ttf') format('truetype'),
            url('../fonts/aktifoa-bookoblique-webfont.svg#aktifo_abook_oblique') format('svg');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'aktifo';
    src: url('../fonts/aktifoa-extrabold-webfont.eot');
    src: url('../fonts/aktifoa-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
            url('../fonts/aktifoa-extrabold-webfont.woff2') format('woff2'),
            url('../fonts/aktifoa-extrabold-webfont.woff') format('woff'),
            url('../fonts/aktifoa-extrabold-webfont.ttf') format('truetype'),
            url('../fonts/aktifoa-extrabold-webfont.svg#aktifo_aextrabold') format('svg');
    font-weight: 800;
    font-style: normal;

}




@font-face {
    font-family: 'aktifo_aextrabold_oblique';
    src: url('../fonts/aktifoa-extraboldoblique-webfont.eot');
    src: url('../fonts/aktifoa-extraboldoblique-webfont.eot?#iefix') format('embedded-opentype'),
            url('../fonts/aktifoa-extraboldoblique-webfont.woff2') format('woff2'),
            url('../fonts/aktifoa-extraboldoblique-webfont.woff') format('woff'),
            url('../fonts/aktifoa-extraboldoblique-webfont.ttf') format('truetype'),
            url('../fonts/aktifoa-extraboldoblique-webfont.svg#aktifo_aextrabold_oblique') format('svg');
    font-weight: 800;
    font-style: italic;

}




@font-face {
    font-family: 'aktifo';
    src: url('../fonts/aktifoa-light-webfont.eot');
    src: url('../fonts/aktifoa-light-webfont.eot?#iefix') format('embedded-opentype'),
            url('../fonts/aktifoa-light-webfont.woff2') format('woff2'),
            url('../fonts/aktifoa-light-webfont.woff') format('woff'),
            url('../fonts/aktifoa-light-webfont.ttf') format('truetype'),
            url('../fonts/aktifoa-light-webfont.svg#aktifo_alight') format('svg');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'aktifo';
    src: url('../fonts/aktifoa-lightoblique-webfont.eot');
    src: url('../fonts/aktifoa-lightoblique-webfont.eot?#iefix') format('embedded-opentype'),
            url('../fonts/aktifoa-lightoblique-webfont.woff2') format('woff2'),
            url('../fonts/aktifoa-lightoblique-webfont.woff') format('woff'),
            url('../fonts/aktifoa-lightoblique-webfont.ttf') format('truetype'),
            url('../fonts/aktifoa-lightoblique-webfont.svg#aktifo_alight_oblique') format('svg');
    font-weight: 300;
    font-style: italic;

}
