body
  &.front_page
    header
      margin-bottom: 12px
  &.content_pages
    .hero
      background $BLUE
      padding-bottom: 0
      margin-bottom: 0

.media_menu
  display: flex
  align-items: center
  justify-content: flex-end
  @media (max-width: 991px)
    padding-right: 50px
  .dropdown_wrap
    position: relative
    padding-top: 6px
  .dropdown-toggle
    background: none
    border: none
    box-shadow: none
    outline: none !important
    padding: 0
    position: relative
    padding-right: 20px
    .dropdown_text
      color: #fff
      font-size: 16px
      line-height: 25px
    .dropdown_arrow
      position: absolute
      width: 10px
      right: 0
      top: calc(50% - 4px)
      height: 7px
      transform: scale(-1)
      display: inline-block
      background: url('../img/dropdown-arow.svg') 0 0 no-repeat

  .dropdown-menu
    left: auto
    right: 0
    margin-top: 9px
    padding: 3px 17px 0
    min-width: 237px
    border: none
    border-radius: 4px
    box-shadow: 0 10px 20px rgba(#585858, .1)
    &:before
      position: absolute
      width: 29px
      height: 29px
      border-radius: 4px
      right: 19px
      top: -3px
      transform: rotate(45deg)
      background: #fff
      content: ''
    .dropdown_item
      display: block
      border-bottom: 1px solid $GREY
      line-height: 25px
      font-size: 16px
      color: #000
      float:none
      text-align: left
      padding-bottom: 10px
      padding-top: 13px
      &:first-child
        padding-top: 10px
      a
        padding: 0
        background: none !important
        color: #000
        text-decoration: none
        &:hover
          color: $LINK
      &:last-child
        border-bottom: none
  .open
    .dropdown-toggle
      .dropdown_arrow
        transform: scale(1)
.nav_listing
  .dropdown_wrap
    position: relative
  .dropdown-toggle
    background: none
    border: none
    box-shadow: none
    outline: none !important
    position: relative
    padding-right: 20px
    .dropdown_arrow
      position: absolute
      width: 10px
      right: 0
      top: calc(50% - 4px)
      height: 7px
      transform: scale(-1)
      display: inline-block
      background: url('../img/dropdown-arow.svg') 0 0 no-repeat

  .dropdown-menu
    left: 0
    right: auto
    margin-top: 9px
    padding: 3px 17px 0
    min-width: 237px
    border: none
    border-radius: 4px
    box-shadow: 0 10px 20px rgba(#585858, .1)
    &:before
      position: absolute
      width: 29px
      height: 29px
      border-radius: 4px
      left: 19px
      top: -3px
      transform: rotate(45deg)
      background: #fff
      content: ''
    .dropdown_item
      display: block
      border-bottom: 1px solid $GREY
      line-height: 25px
      font-size: 16px
      color: #000
      float:none
      text-align: left
      padding-bottom: 10px
      padding-top: 13px
      &:first-child
        padding-top: 10px
      a
        padding: 0
        background: none !important
        color: #000
        text-decoration: none
        &:hover
          color: $LINK
      &:last-child
        border-bottom: none
  .open
    .dropdown-toggle
      .dropdown_arrow
        transform: scale(1)
.burger
  position: absolute;
  padding: 8px
  right 12px
  top 4px
  cursor pointer
  display none
  @media(max-width: 991px)
    display block
    z-index 99
  span
    width: 20px
    height 2px
    background-color #fff
    border-radius: 1px
    display block
    margin-bottom: 4px
    &:last-child
      margin: 0
.action_btn_wrap
  padding-top: 44px
  @media(max-width 768px)
    padding-top: 0
.header_adv_block
  margin-bottom: 60px
  @media (max-width 767px)
    display none
  ul
    list-style-type none
    padding: 0
    margin: 0
    li
      position: relative;
      padding-left: 30px
      margin-bottom: 20px
      color #fff
      font-size 18px
      line-height 24px
      &:last-child
        margin: 0
      &:before
        content ''
        position: absolute;
        left: 0px
        top: 2px
        width: 20px
        height: 20px
        background url('../img/round-check.png') no-repeat
.hero_block
  padding-bottom: 176px
  padding-top: 73px
  @media (max-width: 767px)
    padding-top: 0px
    padding-bottom: 126px
.hero
  background: $BLUE
  padding-bottom: 20px
  &.main_hero
    margin-bottom: 0
    padding-bottom: 0
    @media (max-width: 767px)
      overflow: hidden
    .hero_block
      padding-top: 73px;
      background: $BLUE url('../img/hero_block_image.png') 100% calc(100% + 261px) no-repeat
      @media (max-width: 1200px)
        background-position: 130% calc(100% + 261px)
      @media (max-width: 991px)
        background-position: 50% calc(100% + 261px)
        padding-bottom: 430px
        padding-top: 0
        .hero_title,
        .hero_subtitle
          text-align: center
          max-width: 40%
          margin: 0 auto
      @media (max-width: 767px)
        background-position: 50% calc(100% + 261px)
        padding-bottom: 430px
        padding-top: 0
        .hero_title,
        .hero_subtitle
          max-width: 60%
      @media (max-width: 480px)
        background-position: 50% calc(100% + 115px)
        padding-bottom: 303px
        background-size: 366px auto
        padding-top: 0
        .hero_title
          max-width: 100%
          line-height: 35px
          margin-bottom: 14px
        .hero_subtitle
          max-width: 100%
          line-height: 25px
  &.credit_hero
    margin-bottom: 0
    padding-bottom: 0
    .hero_block
      padding-top: 73px
      background: $BLUE url('../img/hero_credit_image.png') calc(100% + 170px) calc(100% + 125px) no-repeat
      @media (max-width: 1200px)
        background: $BLUE url('../img/hero_credit_image.png') calc(100% + 330px) calc(100% + 125px) no-repeat
      @media (max-width: 991px)
        background: $BLUE url('../img/hero_credit_image.png') calc(100% + 330px) calc(100% + 125px) no-repeat
        padding-top: 0
        .applications_info
          display: block
          .applications_all
            margin-right: 0
            margin-bottom: 20px
        .hero_title,
        .hero_subtitle
          max-width: 70%
      @media (max-width: 767px)
        background: $BLUE url('../img/hero_credit_image.png') 280px -50px no-repeat
        padding-top: 0
        .applications_info
          display: none
      @media (max-width: 767px)
        background-position: calc(50% - -60px) calc(100% + 171px)
        padding-bottom: 430px
        padding-top: 0
        .hero_title,
        .hero_subtitle
          max-width: 60%
          text-align: center
          margin-right: auto
          margin-left: auto
      @media (max-width: 480px)
        background-position: calc(50% + 40px) calc(100% + 65px)
        padding-bottom: 303px
        background-size: 568px auto
        padding-top: 0
        .hero_title
          max-width: 100%
          line-height: 35px
          margin-bottom: 14px
        .hero_subtitle
          max-width: 100%
          line-height: 25px
  @media (max-width: 991px)
    margin-bottom: 40px
  @media (max-width: 768px)
    margin-bottom: 0

.hero_tail
  background: transparent url('../img/bg-tail.png') 50% no-repeat;
  background-size: cover
  height: 54px
header
  padding: 20px 0
  margin-bottom: 0
  background: $BLUE
  @media (max-width: 991px)
    margin-bottom: 22px
    .col-xs-9
      position: static
  @media (max-width: 767px)
    margin-bottom: 11px
    .container
      height: 33px
  .container
    position: relative
  .user-name
    display: block
    border-top: 1px solid #d5d5d5
    padding: 14px 16px
  .logo
    width: 113px
    height: 33px
    position: relative
    text-decoration: none
    margin-right: 22px
    float: left
    font-weight: 600
    display: block
    margin-top: 4px
    a
      color white
    @media (max-width: 767px)
      position: absolute
      top: 0px
      left: calc(50% - (113px / 2))
    svg
      fill: #fff
      width: 113px
      height: 33px
  .centered-logo
    display: flex
    align-items: center
    justify-content: center
    .logo
      margin: 9px 0 8px
      float: none
      display: block

.auth-nav
  display: flex
  align-items: center
  justify-content: flex-end
  a:not(.btn)
    color: #fff
    font-size: 16px
    text-decoration: none
    padding: 0 10px
    margin-right: 18px
    display: block
    position: relative
    &:hover,
    &:focus,
    &:visited,
    &:active
      color #fff
      text-decoration none
      &:after
        content ''
        position: absolute
        width: calc(100% - 20px)
        left 10px
        bottom -4px
        height 2px
        background-color #fff
      @media (max-width: 991px)
        color #9a78fb
        &:after
          display none
  .btn
    padding: 8px 23px 8px 42px
    background-image: url('../img/auth-ico.svg')
    background-position: 16px 11px
    background-repeat: no-repeat
.hero_title
  font-size 40px
  line-height 50px
  font-weight 800
  color #fff
  margin: 0 0 9px
  @media (max-width: 991px)
    font-size 30px
    line-height 40px
.hero_subtitle
  color: #fff
  font-size: 18px
  margin: 0
  line-height: 20px
.applications_info
  padding-top: 83px
  display: flex
  align-items: flex-start
  justify-content: flex-start
  color: #fff
  @media (max-width: 991px)
    display: none
  .applications_all
    margin-right: 131px
  .value
    font-weight: bold
    font-size: 30px
    line-height: 30px
    margin-bottom: 6px
  .info
    font-size: 16px
.nav-container
  visibility: visible
  padding-top: 8px
  margin-left: 140px
  @media (max-width: 991px)
    overflow: hidden
    position: absolute
    right 15px
    top: 50px
    margin-left: 0
    visibility: hidden
    z-index -99
    transform: translateX(100%)
    background-color #fff
    transition: .2s
    border-radius: 4px 0 0 4px
    box-shadow: 1px 1px 12px rgba(0,0,0, 0.4)
    &.is_active
      visibility: visible;
      z-index 1
      transform: translateX(16px)
      transition: .2s
      z-index 999
.nav_listing
  list-style-type none
  margin: 0
  padding 0
  display inline-block
  z-index 1
  li
    float: left
    @media (max-width: 991px)
      float none
      display block
  a
    color #fff
    text-decoration none
    display inline-block
    padding: 0 16px
    font-size 16px
    line-height: 19px
    position: relative
    @media (max-width: 991px)
      color #9a78fb
      width: 100%
      padding: 14px 16px
      font-weight 400
    &:hover,
    &:focus,
    &:visited,
    &:active
      color #fff
      text-decoration none
      &:after
        content ''
        position: absolute
        width: calc(100% - 32px)
        left 16px
        bottom -4px
        height 2px
        background-color #fff
      @media (max-width: 991px)
        color #9a78fb
        &:after
          display none

.logos_block
  margin-bottom: 84px
  margin-left: -16px
  display none
  @media (max-width: 991px)
    display none
  ul
    list-style-type none
    margin 0 auto
    padding 0
    width: 100%;
    img
      opacity: .3
    li
      float left
      padding: 0 16px

.error_no_page
  text-align left
  color #fff
  .fof_block
    font-size 100px
    line-height 102px
    font-weight: 700
    margin-bottom: 10px
  span
    font-size 26px
    line-height 30px
    font-weight: 700
    margin-bottom: 10px
    display inline-block
  p
    font-size 14px
    line-height 16px
    margin-bottom: 0
