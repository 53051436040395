
// // Imports from file
@import 'mixins'
@import 'grid'
@import 'animations'
@import 'buttons'
@import 'checkbox'
@import 'radio'
@import 'drop_list'
@import 'validation'
@import 'knob'
@import 'partners'
@import 'slider'
@import 'slider_hint'
@import 'promo.styl'


//normalize-css()

.finance_form

	font-family $BASE_FONT
	font-size 15px
	line-height 1.3
	color #000
	.feed_item
		.title
			text-align: left
	.step
		background #f7f7f7
		border 2px solid #d5dde9

	.btn
		text-decoration: none !important
	a:not(.btn)
		color #3886d7
		text-decoration none
		&:hover
			text-decoration underline

	.form_head
		text-align center
		//margin-bottom 20px
		padding 20px
		background linear-gradient(to bottom, #f9f9f9, #efefef)
		border-bottom 2px solid #d5dde9

		.form_header
			font-size 20px
			line-height 1.3
			margin-bottom 7px

		.form_subheader
			font-size 16px
			line-height 1.3

		.form_subheader_mark
			color #f65a5a
			font-weight bold

	.form_body
		padding 0 15px

		.form_section_head
			//color #a2a5ae
			color #74a3c7
			text-transform uppercase
			margin-bottom 15px
			font-size 15px
			line-height 20px

		.form_block
			position relative
			margin-bottom 15px
			&.centered
				text-align center

		.form_label_block, .radio_head
			display block
			margin-bottom 10px
			color #474c51
			//overflow ellipsis
			//font-size 14px
		select.form_input_block
			padding 0 0 0 5px

		.form_input_block
			border 2px solid #e3e3e3
			height 40px
			line-height 40px
			padding 0 10px
			border-radius 3px
			background-color #fefefe
			//box-shadow inset 0 0 5px rgba(0, 0, 0, 0.2)
			width 100%
			font-size 16px
			color #000000
			font-family inherit
			&:focus
				outline #fed500 auto 2px
				border-color #dcdac0
				background-color #fffef5
			&[disabled]
				cursor not-allowed
				background-color #eee
				opacity 1


		// ========== dinamic Element ==========
		.form_input_hint
			position absolute
			top 100%
			left 0
			width 100%
			color $COLOR_1
			padding 5px 0
			line-height: 1
			font-size: 12px
			z-index 10


		.success_block
			max-width 365px
			margin auto
			padding-top 10px
			.success_block_title
				font-size 18px
				text-align center
				line-height 1.4
			.success_sign
				display block
				color #fff
				background-color #339e0e
				width 90px
				height 90px
				line-height 90px
				font-size 40px
				text-align center
				border-radius 50%
				margin 25px auto
			.success_block_subtitle
				font-size 16px
				line-height 1.4
				text-align center
				margin 0 0 30px
				//padding-right 60px
				//background: url('//s3-eu-west-1.amazonaws.com/static.leadia.ru/forms/paydayru/service_arrow.png') 100% 50% no-repeat

		.view_full_vitrine
			text-align center
			padding-bottom 20px
			.view_full_link
				margin 0

#credit_hint_wrap
	display none !important

.finance_form .checkbox_block .form_checkbox:checked + label:before
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	margin: 8px 0 0 2px;
	background: url(../img/checked.png) center 0 no-repeat;
	background-size: cover;


.apart_form_container
	margin-bottom: 40px
	h2
		text-align left
		font-size 30px
		line-height 40px
		font-weight 400
		max-width: 940px;
		margin-left auto
		margin-right: auto
	.main_slider_block
		max-width: 940px;
		margin: 0 auto;
		border: 2px solid #e9eef3;
