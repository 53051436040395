.footer
  padding: 33px 0 30px
  background: #fff
  @media (max-width: 480px)
    padding-bottom: 24px
  .foot-logo
    text-decoration: none
    margin-right: 50px
    float: left
    display: block
    @media (max-width: 480px)
      margin-right: 0
      margin-bottom: 18px
    svg
      fill: $BLUE
      width: 113px
      height: 33px
  .footer-menu
    display: flex
    align-items: center
    justify-content: flex-start
    padding-top: 4px
    @media (max-width: 767px)
      flex-wrap: wrap
    @media (max-width: 480px)
      width: 100%
      align-items: flex-start
      flex-direction: column
      flex-wrap: nowrap
    a
      font-size: 16px
      line-height: 25px
      color: #000
      margin-right: 40px
      display: block
      @media (max-width: 767px)
        margin-bottom: 10px
      &:hover
        text-decoration: none
        color: $LINK
  .footer-item
    display: flex
    align-items: flex-start
    justify-content: flex-start
    margin-bottom: 43px
    @media (max-width: 767px)
      margin-bottom: 20px
      flex-wrap: wrap
    @media (max-width: 480px)
      margin-bottom: 14px
  p
    font-size: 14px
    line-height: 25px
    margin: 0 0 37px
    color: #000
    &.text-2
      font-size: 12px
      line-height: 20px
      color: #888
      margin: 0
